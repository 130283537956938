.sidenav
{
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    height: 100%;
    position: relative;

}

.navhead
{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 50px;
}

.heading
{
    
    font-size: 3em;
    margin: 0px;
    color: var(--brown-dark);
    padding: 7px;
}

.sidenav h4
{
    margin: 10px 0;
    font-size: 1.3rem;
    cursor: pointer;
    padding: 7px 10px;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: var(--brown-dark);
    width: 73%;
    min-width: 250px;
}
.icon
{
    margin: 0px 10px 0px 0px;
}
.navlogo
{
    height: 50px;
    margin: 0px 5px;
}
.sidenav h4:hover
{
    background-color: var(--purple-light);
    color: black;
    opacity: 0.7;
}
.sidenav h4.active
{
    background-color: #d4b2f3;
    color: var(--primary);
}
.sidenav .add
{
    justify-content: center !important;
    background-color: var(--purple-dark);
    align-self: center !important;
    color: var(--primary);
}


.sidenavIcons
{
    position: absolute;
    bottom: 0;
    left: 0;
    margin-right: auto;
    width: 100%;
    margin-top: auto;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
}


.delete-all
{
    padding: 4px;
    font-size: 1.7em;
    margin: 0px 10px;
    margin-left: 30px;
    cursor: pointer;
    transition: 0.3s;
}

.delete-all:hover
{
    transform: scale(1.3);
    transition: 0.3s;
}