.dashboard
{
    display: flex;
    flex-direction: row;
    height: 100vh;
    max-height: 100vh;
    padding: 0px;
    background-color: var(--primary);
    position: relative;
}

.nav
{
    height: 80%;
    width: 30vw;
    padding: 30px;
}

.dashright
{
    width: 100%;
    padding: 30px;
    display: flex;
    flex-direction: column;
}

.welcome
{
    font-size: 2.5rem;
    margin-top: 12px;
}

::-webkit-scrollbar {
    width: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
}