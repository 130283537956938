.card
{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}

.card .cardTitle
{
    width: 25%;
}