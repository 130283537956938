.backdrop
{
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: transparent;
}

.expanded
{
    
    background-color: var(--primary);
    color: rgb(27, 27, 27);
    padding: 1rem;
    border-radius: 7px;

    overflow: scroll;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    transition: 0.2s;
    
    
    
    position: absolute;
    width: 70%;
    height: 70%;
    max-height: none;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    padding-bottom: 10px;
    z-index: 2;
    animation: 0.5s appear2;
    box-shadow: 0px 0px 100px 1000px rgba(0, 0, 0, 0.568);
}

@keyframes appear2 {

    0%{
        transform: scale(0) translate(-50%,-50%);
    }
    100%{
        transform: scale(1) translate(-50%,-50%);
    }
    
}

.close-icon
{
    cursor: pointer;
    transition: 0.2s;
    align-self: flex-end;
}
.close-icon:hover
{
    transform: scale(1.3);
    transition: 0.2s;
}

.contents
{
    height: 90%;
    width: 95%;
    margin: 10px;
    background-color: rgba(206, 206, 206, 0.637);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.contents form input{
    width: 100%;
    text-align: center;
}

.addfile
{
    border: 2px solid var(--purple-dark) !important;
}

#filename
{
    text-align: left;
}